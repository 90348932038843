<template>
	<div class="links-container">
		<div class="link" 
		v-for="(link, index) in links" 
		:key="`link-${index}`"
		v-bind:class="{
			'disabled': !link.name
		}"
		@click="redirectTo(link)">
			{{ link.title }}
		</div>
	</div>
</template>

<script>


export default {
	name: 'UsefulLinks',


	data() {
		return {
			links: [
				{title: "Terms", name: "terms"},
				{title: "Privacy", name: "privacy-policy"},
				{title: "Help", },
				{title: "Company",},
			],
		};
	},

	mounted() {
		
	},

	methods: {
		redirectTo(link){
			console.log('link :>> ', link);
		if (!link || !link.name)
				return
			
			if (this.$route.name == 'home')
				localStorage.setItem('originPage-home', JSON.stringify({name: 'home'}))
			if (this.$route.name == 'auth')
				localStorage.setItem('originPage-auth', JSON.stringify({name: 'auth'}))
			this.$router.push({name: 'docs', params: {
				docName: link.name
			}})
		}
	},
};
</script>

<style scoped>

.links-container{
	position: fixed;
	bottom : 0;
	left: 0;
	height: 50px;
	width: 100%;
	background: white;

	display: flex;
	flex-direction: row;
	border-top: 1px solid #e6e6e6;
	
}

.link{
	user-select: none;
	cursor: pointer;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	text-decoration: underline;
	color: #006ce5;
}

.link + .link{
	border-left: 1px solid #e6e6e6;
}

.link.disabled{
	color: #b3b3b3;
	cursor: not-allowed;
}
</style>