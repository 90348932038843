<template>
    <div class="home-page" v-bind:class="{
		'without-toolbar' : !isAuthenticated,
	}">
		<div class="home-page__main-info" v-if="!isAuthenticated">
			<div class="home-page__title">
				<div class="title-part">HORA</div>
				<div class="title-part">GURU</div>
			</div>

			<div class="home-page__description">
				Take Control on Your Tutoring Fees
			</div>

			<div class="home-page__start-use">
				<b-button type="is-info" expanded @click="goToAuth">
					Start Using for Free
				</b-button>
			</div>
		</div>
		
		<!-- <div>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec finibus pharetra neque eu tincidunt. Proin non sagittis leo. Sed facilisis ut diam vel consectetur. 
			Vestibulum eros enim, tempus et pharetra at, pulvinar at eros.
		</div>
        
		<div>
			Vestibulum auctor ligula est, vel commodo leo varius eu. Donec eu sodales arcu. Duis ullamcorper ligula vitae justo luctus, at ornare ipsum sagittis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent euismod vulputate ex.
		</div>
		
		<div> Mauris ultricies vitae nunc ut bibendum. Nulla facilisi. Sed quis quam suscipit, elementum turpis quis, vulputate arcu. Sed maximus ante tincidunt, venenatis tellus et, congue augue.</div>

		<div>
			Ut tristique turpis sit amet nisi cursus, et viverra lorem consequat. Ut justo neque, hendrerit ac sodales sit amet, tincidunt nec quam. Nam faucibus volutpat augue in interdum. Fusce posuere, ligula vitae lacinia consequat, justo dui malesuada orci, vel luctus purus tortor vitae risus.
		</div>

		<div>
			Sed a fringilla urna, vel sodales odio. Nam vehicula, justo eu tempus volutpat, velit turpis pellentesque dolor, at imperdiet enim sapien et ex. Nam vitae felis porttitor, malesuada urna nec, dignissim lectus.
		</div>

		<div>
			 Ut pellentesque felis augue, elementum dignissim augue euismod consequat. Fusce et risus nulla. Proin pretium quam non vestibulum consequat. Sed vel efficitur lectus. Fusce id felis in nunc dignissim aliquam et ultrices sapien. Nunc accumsan tellus at nibh porttitor ullamcorper.
		</div>

		<div class="home-page__sign-in">
			<div class="sign-in__title">Sign In</div>
			<b-button type="is-info" expanded>
				To auth page
			</b-button>
		</div> -->

		<UsefulLinks/>
    </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import StaticMenu from '../components/static-menu/StaticMenu.vue'
import UsefulLinks from "../components/UsefulLinks.vue";
import TokenService from "../service/token-service";


export default {
    name: 'Home',
    components: {
        Header,
        StaticMenu,
		UsefulLinks,
    },

	computed: {
		isAuthenticated() {
			return TokenService.tokenIsExists()
		}
	},

	beforeMount() {
		if(this.isAuthenticated){
			this.redirectToDefaultCalendar()
		}
	},

    methods: {
		goToAuth(){
			this.$router.push({name: 'auth'})
		},
		redirectToDefaultCalendar(){

			const calendarUid = this.$store.getters.getDefaultCalendar
			if (!calendarUid) {
				return setTimeout(() => this.redirectToDefaultCalendar(), 100)
			}
			this.$router.push({
				name: 'calendar-schedule', 
				params: {
					calendarUid: calendarUid, 
				}
			})
		},
    }
}
</script>

<style scoped>
.home-page{
    padding: 24px;
	height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.without-toolbar{
	height: 100%;
	margin-bottom: 0;
}

.home-page__main-info{
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: center;
	margin-bottom: 36px;
}


.home-page__title{
	width: 100%;
	height: 96px;
	font-size: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 20px;
}

.title-part{
	height: 40px;
	text-align: center;
}

.home-page__description{
	font-size: 24px;
	text-align: center;
	padding: 0 20px;
}



.home-page__sign-in{
	height: 400px;
	padding: 72px 0;
	width: 100%;

	display: flex;
	flex-direction: column;
	gap: 32px;
}

.sign-in__title{
	font-size: 30px;
	text-align: center;
}

</style>