<template>
    <div class="static--menu--item" @click="usage" v-bind:class="{
        selected: isSelected
    }">
        <div class="icon_cont">
            <span class="iconify" data-width="30" data-height="30" :data-icon="icon"></span>
        </div>
    </div>    
</template>

<script>
export default {
    name: 'Item',
    props:{
        icon: String,
        isSelected: Boolean,
        usage: {
            type: Function,
            default: () => console.log('press on static button')
        },
    },
}
</script>

<style scoped>
.static--menu--item{
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon_cont{
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected{
    color: #0075ff
}
</style>