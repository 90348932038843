var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"menu"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],staticClass:"static__menu",attrs:{"id":"static__menu"}},[_vm._l((_vm.buttons.slice(0, 2)),function(button){return _c('Item',{key:'static-menu-button-' + button.icon,attrs:{"usage":function () {
            if (button.usage){
                button.usage()
            }
        },"icon":button.icon,"isSelected":_vm.isSelected(button.name)}})}),_c('CreateSlotItem'),_vm._l((_vm.buttons.slice(2, 4)),function(button){return _c('Item',{key:'static-menu-button-' + button.icon,attrs:{"usage":function () {
            if (button.usage){
                button.usage()
            }
        },"icon":button.icon,"isSelected":_vm.isSelected(button.name)}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }