<template>
    <transition name="menu">
        <div class="static__menu" id="static__menu" v-show="isVisible">
            <Item 
            v-for="button in buttons.slice(0, 2)" 
            :key="'static-menu-button-' + button.icon"
            :usage="() => {
                if (button.usage){
                    button.usage()
                }
            }"
            :icon="button.icon"
            :isSelected="isSelected(button.name)" 
            /> 
            <CreateSlotItem/>
            <Item 
            v-for="button in buttons.slice(2, 4)" 
            :key="'static-menu-button-' + button.icon"
            :usage="() => {
                if (button.usage){
                    button.usage()
                }
            }"
            :icon="button.icon"
            :isSelected="isSelected(button.name)" 
            /> 
        </div>
    </transition>
</template>

<script>
import Item from './Item.vue'

import router from '../../router.js'
import TokenService from '../../service/token-service'
import CreateSlotItem from './CreateSlotItem.vue'

export default {
    name : 'StaticMenu',
    components: {
        Item,
        CreateSlotItem,
    },
	props: {
		isVisible: {
			type: Boolean,
			default: true
		}
	},
    data(){
        return {
            currentPageName: this.$route.name,
            buttons: [
                { name: "home",         		icon: "bx:home",    usage: () => this.goTo("home")},
                { name: "calendar-list",		icon: "bx:search",  usage: () => this.goTo("calendar-list")},

                { name: "calendar",    			icon: "material-symbols:calendar-view-day-outline-rounded"},
                { name: "calendar-schedule",    icon: "ic:round-perm-contact-calendar", usage: this.goToDefaultCalendar},
            ],
        }
    },
    watch: {
        $route(to){
            this.updateSelectedButton(to.name)
        },
    },
    mounted() {
        this.updateSelectedButton(this.$route.name)
    },
    methods: {
        goTo(routeName){
            router.push({name: routeName}).catch(() => {})
        },
		goToCalendarSchedule(){
			let calendarUid 
			let currentRoute = this.$route
			if (currentRoute.name == 'calendar') {
				calendarUid = currentRoute.params.calendarUid
			} else {
				calendarUid = this.$store.getters.getDefaultCalendar
			}
			this.$router.push({name: 'calendar-schedule', params: {calendarUid}})
		},
        goToDefaultCalendar(){
            let isAuth = TokenService.tokenIsExists()
            if (!isAuth) {
                router.push({name: "auth"})
                return
            }

            let defaultCalendar = this.$store.state.user.defaultCalendar
            router.push({
				name: 'calendar-schedule', 
				params: {
					calendarUid : defaultCalendar
				}, 
				replace: true 
			})
        },
        goToHome(){
            router.push({name:'home'})
        },
        updateSelectedButton(currentPageName){
             
            // Default calendar check
            if (currentPageName == 'calendar'){
                let defaultCalendarUid = this.$store.state.user.defaultCalendar 
                let currentCalendarUid = this.$route.params.calendarUid
                if (currentCalendarUid == defaultCalendarUid){
                    this.currentPageName = 'calendar'
                }
                else {
                    this.currentPageName = ''
                }
                return
            }

            this.currentPageName = currentPageName
        },
        isSelected(buttonName){
            return buttonName == this.currentPageName
        },
 
    },
}
</script>

<style scoped>
.static__menu{
    display: grid;
    grid-template-columns: repeat(5, 20%);
    background: white;
    height: 50px;
    width: 100%;
    position: fixed;

    z-index: 4;
    border-top: 1px solid gray;
    transition: bottom 0.5s ease-in-out;
    bottom: 0;
}


.menu-leave-active {
	animation: bounce-in .5s ease-out;
}
.menu-enter-active {
	animation: bounce-in .5s ease-in reverse;
}
@keyframes bounce-in {
	from {
		bottom: 0;
	}
	to {
		bottom: -50px;
	}
}

</style>